.App {
    text-align: center;
}
.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-intro {
    font-size: large;
}

.searchInputBaseClass div {
    height: 50px;
}

input:-internal-autofill-selected {
    background-color: #EDEEEF;
}
/*.MuiTableCell-body:last-child:last-child,*/
.mystarInvoiceList tbody tr td:last-child,
.Operations tbody tr td:last-child,
.column-gridListAction
{
    border-left: 1px solid #E0E0E0;
    padding: 2px !important;
    text-align: center !important;
    max-width: 75px;
}


@media only screen and (min-width: 600px) {
    .App-gridContainerSummariesTotal {
        max-width: 20% !important;
        flex-basis: 20% !important;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
